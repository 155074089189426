import { Injectable } from "@angular/core";

@Injectable()
export class HeaderService {
  private baseUrl =
    'https://widget.trustpilot.com/trustbox-data/54ad5defc6454f065c28af8b?businessUnitId=5a05e3ce0000ff0005b01862&locale=en-GB&reviewLanguages=en&reviewStars=4%2C5&reviewsPerPage=1';
  constructor() {}

  async fetchTrustPilotData() {
    try {
      const response = await fetch(this.baseUrl, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.text();
    } catch (error) {
      console.error('Error in contact API:', error);
      throw error;
    }
  }
}
