import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { OffersService } from 'src/app/offers/offers.service';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { HeaderService } from './header.service';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    LoaderModule,
  ],
  providers: [OffersService, HeaderService],
  exports: [HeaderComponent],
})
export class HeaderModule {}
